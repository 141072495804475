import { memo, startTransition, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { CarouselMetaContext } from 'site-modules/shared/components/usurp-inventory-card/usurp-inventory-card-carousel/carousel-meta-context';

const NUMBER_OF_PRELOADED_SLIDES = 2;

export const CarouselLazySlide = memo(props => {
  const { slideIndex, children } = props;
  const { currentSlide } = useContext(CarouselMetaContext);
  const [isRendered, setIsRendered] = useState(currentSlide === slideIndex);

  useEffect(() => {
    if (isRendered) {
      return;
    }

    if (currentSlide && slideIndex - currentSlide <= NUMBER_OF_PRELOADED_SLIDES) {
      startTransition(() => {
        setIsRendered(true);
      });
    }
  }, [currentSlide, isRendered, slideIndex]);

  return isRendered && children;
});

CarouselLazySlide.propTypes = {
  slideIndex: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
};

CarouselLazySlide.displayName = 'CarouselLazySlide';
