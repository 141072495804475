import React from 'react';

import { connect } from 'react-redux';
import { get, merge, reduce } from 'lodash';

import { profileScreenMethods } from 'site-modules/shared/components/profile/profile-screen-methods';
import { insiderMethods } from 'site-modules/shared/components/profile/insider-methods';
import { bindToPath, connectToModel } from 'client/data/luckdragon/redux/react-binding';
import { UserProfileModel } from 'client/data/models/profile/profile';
import { getSaveDealAttributes, getSaveVinAttributes } from 'site-modules/shared/components/profile/attributes/bundles';
import { logger } from 'client/utils/isomorphic-logger';
import { isAnonymousUserWithAppraisalData } from 'site-modules/shared/components/profile/firebase-anonymous-auth';
import {
  authenticatedAnonymousSaveAction,
  authenticatedSaveVinAction,
} from 'client/engagement-handlers/profile-engagement-handler/profile-engagement-handler';

export function insiderSaveVinWrapper(WrappedComponent, allowToggle = false, authCreativeId = 'save-vin-anonymous') {
  function InsiderSaveVin(props) {
    const {
      isAnonymousUserWithAppraisal,
      toggleSignModal,
      savedVins,
      isAuthenticated,
      handleNonEmailUser,
      ...restProps
    } = props;

    const {
      signInAnonymousWithToggleModalAndSave,
      showModal,
      vehicle,
      modelLinkCode,
      userHasEmail,
      removeDataFromIdm,
      updateIdmData,
      edmundsDeals,
    } = props;

    const vin = get(vehicle, 'vin');
    // Checks if vehicle is active state
    const isActive = allowToggle ? !!(savedVins && savedVins[vin]) : false;
    const isAuthenticatedUserWithNoEmail = isAuthenticated && !userHasEmail && handleNonEmailUser;

    const trackingId =
      isAuthenticated && (!handleNonEmailUser || isAuthenticatedUserWithNoEmail) ? 'vin_save_vehicle' : '';

    // Detects if user is authenticated and starts appropriate action
    function startInsiderAction(event, dealData) {
      // Sends async request with data selected to IDM
      function saveVin(dataToPass) {
        const savedDeal = edmundsDeals.find(sd => sd.vin === vehicle.vin);
        const isRemoveSavedDeal = isActive && savedDeal;

        return (isActive ? removeDataFromIdm : updateIdmData)(
          merge(dataToPass, isRemoveSavedDeal && { edmundsDeals: [savedDeal] })
        )
          .then(() => isAuthenticatedUserWithNoEmail && toggleSignModal())
          .catch(() => {
            logger('warn', 'vehicle is not saved to a storage properly');
          });
      }

      // Created anonymous profile and saves VIN then opens a authenticated save modal
      async function authenticateSave(dataToPass) {
        try {
          await signInAnonymousWithToggleModalAndSave(dataToPass, {
            creativeId: authCreativeId,
            showModal,
          });

          if (dataToPass.vehicles && !isAnonymousUserWithAppraisal) {
            authenticatedSaveVinAction(Object.keys(dataToPass.vehicles.vins)[0]);
          }
        } catch (e) {
          logger('warn', 'vehicle is not saved to a storage properly', e);
        }
      }

      const vehicleData = { ...vehicle, modelLinkCode };
      let processSavedVin = saveVin;

      if (isAuthenticatedUserWithNoEmail && isActive) {
        return toggleSignModal();
      }

      if (isAuthenticated) {
        if (isAnonymousUserWithAppraisal) {
          authenticatedAnonymousSaveAction('ANONYMOUS_UPGRADE_SAVE_VIN');
          processSavedVin = authenticateSave;
        }
      } else {
        processSavedVin = authenticateSave;
      }

      const dataToPass = dealData
        ? getSaveDealAttributes(vehicleData, isActive, dealData)
        : getSaveVinAttributes(vehicleData, isActive);
      return processSavedVin(dataToPass);
    }

    return (
      <WrappedComponent
        {...restProps}
        isAuthenticated={isAuthenticated}
        handleNonEmailUser={handleNonEmailUser}
        startInsiderAction={startInsiderAction}
        isActive={isActive}
        trackingId={trackingId}
      />
    );
  }

  InsiderSaveVin.defaultProps = {
    showModal: true,
    edmundsDeals: [],
  };

  return InsiderSaveVin;
}

const stateToPropsConfig = {
  savedVins: bindToPath(props => (props.isAuthenticated ? 'data.idm' : ''), UserProfileModel, idm =>
    reduce(get(idm, 'vehicles.vins', {}), (acc, attrs, vin) => ({ ...acc, [vin]: !!attrs.savedTs }), {})
  ),
};

const mapStateToProps = state => ({
  isAuthenticated: get(state, 'profile.isAuthenticated'),
  userHasEmail: !!get(state, 'profile.data.idm.identifiers.email'),
  isAnonymousUserWithAppraisal: isAnonymousUserWithAppraisalData(state),
  edmundsDeals: get(state, 'profile.data.idm.edmundsDeals'),
});

export const connectedInsiderSaveVinWrapper = (WrappedComponent, allowToggle) => {
  const component = profileScreenMethods(
    insiderMethods(
      connect(mapStateToProps)(
        connectToModel(insiderSaveVinWrapper(WrappedComponent, allowToggle), stateToPropsConfig)
      ),
      { waitForAuth: true }
    )
  );
  component.displayName = `InsiderSaveVinWrapper(${WrappedComponent.displayName || WrappedComponent.name || 'Base'})`;
  return component;
};
