import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Tooltip from 'reactstrap/lib/Tooltip'; // eslint-disable-line no-restricted-imports
import classnames from 'classnames';
import { navigationClose } from 'client/engagement-handlers/profile-engagement-handler/profile-engagement-handler';
import { INSIDER_PATHS } from 'site-modules/shared/constants/profile/dashboard-paths';
import { Link } from 'site-modules/shared/components/link/link';
import { PREVENTABLE_CLOSE_CLASSES } from './tooltip-utils';

import './inventory-heart-tooltip.scss';

export function InventoryHeartTooltip({
  target,
  className,
  isOpen,
  placement,
  flip,
  hideTooltipOnScroll,
  closeCallback,
}) {
  const initialScrollY = useRef();

  useEffect(() => {
    function tooltipClose(event) {
      if (!event.target.closest(PREVENTABLE_CLOSE_CLASSES.join(','))) {
        closeCallback();
        navigationClose('inventory-heart-tooltip');
      }
    }

    function hideOnScroll() {
      if (Math.abs(initialScrollY.current - window.scrollY) > 0) {
        closeCallback();
      }
    }

    document.removeEventListener('click', tooltipClose);
    document.addEventListener('click', tooltipClose);

    if (hideTooltipOnScroll) {
      document.addEventListener('scroll', hideOnScroll);
      initialScrollY.current = window.scrollY;
    }

    return () => {
      closeCallback();
      document.removeEventListener('click', tooltipClose);
      document.removeEventListener('scroll', hideOnScroll);
    };
  }, [closeCallback, hideTooltipOnScroll]);

  const tooltipText = "Saved! We've added this car to";

  return (
    <Tooltip
      className={classnames('inventory-heart-tooltip', className)}
      target={target}
      isOpen={isOpen}
      placement={placement}
      flip={flip}
    >
      <div className="small text-gray-dark" data-tracking-parent="inventory-heart-tooltip">
        <span>{tooltipText}</span>{' '}
        <Link
          to={INSIDER_PATHS.INVENTORY_PAGE}
          className="text-primary"
          data-tracking-id="inventory_heart_tooltip_link"
          rel="nofollow"
        >
          your Dashboard
        </Link>
        .
      </div>
    </Tooltip>
  );
}

InventoryHeartTooltip.propTypes = {
  target: PropTypes.shape({ current: PropTypes.object }).isRequired,
  closeCallback: PropTypes.func.isRequired,
  className: PropTypes.string,
  isOpen: PropTypes.bool,
  flip: PropTypes.bool,
  placement: PropTypes.string,
  hideTooltipOnScroll: PropTypes.bool,
};

InventoryHeartTooltip.defaultProps = {
  className: null,
  isOpen: false,
  flip: true,
  placement: 'left',
  hideTooltipOnScroll: false,
};
