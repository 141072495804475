import { flow } from 'lodash';
import { stripHTML, transformNewlines } from 'site-modules/shared/utils/strip-html';
import { replaceNullChars } from 'site-modules/shared/utils/string';

const FEES_DISCLAIMER_REGEXP = /{fees}(.+){fees}/m;

export const stripSellerFeesDisclaimer = comment => comment.replace(FEES_DISCLAIMER_REGEXP, '');

export const extractSellerFeesDisclaimer = comment => comment.match(FEES_DISCLAIMER_REGEXP)?.[1]?.trim() ?? '';

/**
 * We should not support any html in these comments.  We only expect
 * '\n' (newline characters) strings that should be replaced with '<br>'.
 *
 * @param {string} content
 * @returns {string}
 */
export const transformInventoryComments = flow(
  replaceNullChars,
  stripHTML,
  stripSellerFeesDisclaimer,
  transformNewlines
);
