import React from 'react';
import PropTypes from 'prop-types';
import { marked } from 'marked'; // eslint-disable-line import/extensions
// the above is to disable eslint error: Missing file extension "cjs" for "marked"  import/extensions
// eslint's complaint here is not valid because
// 1. on server side this import resolves to marked/lib/marked.cjs, on client it resolves to marked/lib/marked.esm.js, eslint is not aware of this
// 2. we should never import a cjs file in any isomorphic module cause cjs is only for node.

import { ContentFragment } from 'site-modules/shared/components/content-fragment/content-fragment';

/**
 * Markdown view
 * @param markdown
 * @param options  https://marked.js.org/#/USING_ADVANCED.md#options
 * @param className
 */
export function MarkdownView({ markdown, options, className, fragmentRef }) {
  return (
    <ContentFragment ref={fragmentRef} classes={className}>
      {marked.parse(markdown, options)}
    </ContentFragment>
  );
}

MarkdownView.propTypes = {
  markdown: PropTypes.string.isRequired,
  options: PropTypes.shape({}),
  className: PropTypes.string,
  fragmentRef: PropTypes.shape({ current: PropTypes.object }),
};

MarkdownView.defaultProps = {
  options: {},
  className: '',
  fragmentRef: null,
};
