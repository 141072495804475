import React, { useCallback, useId, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get, isEmpty } from 'lodash';
import classnames from 'classnames';
import { useToggle } from 'site-modules/shared/hooks/use-toggle';
import { TrackingConstant } from 'client/tracking/constant';
import { formatPriceString } from 'site-modules/shared/utils/price-utils';
import { connectedInsiderSaveVinWrapper } from 'site-modules/shared/components/profile/insider-save-vin-wrapper/insider-save-vin-wrapper';
import { HeartButton } from 'site-modules/shared/components/heart-button/heart-button';

import { InventoryHeartTooltip } from './inventory-heart-tooltip';

function InventoryHeartUI({
  isAuthenticated,
  CustomView,
  vehicle,
  isMobile,
  emptyIdmData,
  classes,
  userHasEmail,
  handleNonEmailUser,
  spinnerSize,
  tooltipFlip,
  tooltipPlacement,
  trackingId,
  isActive,
  emptyProfileScreen,
  noSpinnerOnLoad,
  noCopy,
  hideTooltipOnScroll,
  isSrp,
  wrapperClasses,
  ariaLabel,
  startInsiderAction,
  forceNoTooltip,
}) {
  const [enableTooltip, setEnableTooltip] = useState(false);
  const [inProgress, toggleProgress] = useToggle(false);
  const tooltipWrapperRef = useRef();
  const tooltipTarget = `inventory-heart-custom-view-${get(vehicle, 'vin', '')}-${useId()}`;

  const handleClick = useCallback(
    (event, dealData) => {
      toggleProgress();

      return startInsiderAction(event, dealData)
        .then(() => {
          if (!forceNoTooltip) {
            setEnableTooltip(!isActive);
          }
        })
        .then(toggleProgress);
    },
    [forceNoTooltip, isActive, startInsiderAction, toggleProgress]
  );

  const tooltipCloseCallback = useCallback(() => {
    setEnableTooltip(false);
  }, []);

  const { vin } = vehicle;
  const { make = '', model = '', year = '', trim = '' } = get(vehicle, 'vehicleInfo.styleInfo', {});
  const displayPrice = get(vehicle, 'prices.displayPrice', 0);
  const ariaLabelExtra =
    make && model && year
      ? `${displayPrice ? formatPriceString(displayPrice) : ''} ${year} ${make} ${model} ${trim}`.trim()
      : '';

  if (!CustomView) {
    return (
      <HeartButton
        onClick={handleClick}
        className={classnames('text-center-zdepth-1', classes)}
        inProgress={inProgress}
        isActive={isActive}
        trackingId={trackingId || 'vin_sign_up_progress'}
        trackingValue={vin}
        trackingSubactionName={isActive ? TrackingConstant.UNSAVE_VIN : TrackingConstant.SAVE_VIN}
        ariaLabelExtra={ariaLabel || ariaLabelExtra}
        isSrp={isSrp}
      />
    );
  }

  return (
    <div id={tooltipTarget} ref={tooltipWrapperRef} className={classnames('inventory-heart-wrapper', wrapperClasses)}>
      <CustomView
        active={isActive}
        spinnerSize={spinnerSize}
        disabled={inProgress || !!(isAuthenticated && emptyIdmData)}
        isAuthenticated={isAuthenticated}
        onClick={handleClick}
        userHasEmail={userHasEmail && handleNonEmailUser}
        trackingId={trackingId}
        trackingValue={vin}
        isMobile={isMobile}
        className={classes}
        vin={vin}
        noSpinner={noSpinnerOnLoad && isAuthenticated && emptyIdmData}
        noCopy={noCopy}
      />
      {enableTooltip && (
        <InventoryHeartTooltip
          target={tooltipWrapperRef}
          isOpen={emptyProfileScreen && isActive}
          placement={tooltipPlacement}
          flip={tooltipFlip}
          closeCallback={tooltipCloseCallback}
          hideTooltipOnScroll={hideTooltipOnScroll}
        />
      )}
    </div>
  );
}

InventoryHeartUI.propTypes = {
  vehicle: PropTypes.shape({
    vin: PropTypes.string.isRequired,
    vehicleInfo: PropTypes.shape({
      styleInfo: PropTypes.shape({
        make: PropTypes.string,
        year: PropTypes.number,
        model: PropTypes.string,
      }),
    }),
  }).isRequired,
  isAuthenticated: PropTypes.bool,
  CustomView: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  isMobile: PropTypes.bool,
  emptyIdmData: PropTypes.bool,
  emptyProfileScreen: PropTypes.bool,
  classes: PropTypes.string,
  forceNoTooltip: PropTypes.bool,
  userHasEmail: PropTypes.bool,
  handleNonEmailUser: PropTypes.bool,
  spinnerSize: PropTypes.number,
  tooltipFlip: PropTypes.bool,
  tooltipPlacement: PropTypes.string,
  trackingId: PropTypes.string,
  isActive: PropTypes.bool,
  startInsiderAction: PropTypes.func.isRequired,
  noSpinnerOnLoad: PropTypes.bool,
  noCopy: PropTypes.bool,
  hideTooltipOnScroll: PropTypes.bool,
  isSrp: PropTypes.bool,
  wrapperClasses: PropTypes.string,
  ariaLabel: PropTypes.string,
};

InventoryHeartUI.defaultProps = {
  isAuthenticated: null,
  CustomView: null,
  isMobile: false,
  emptyIdmData: true,
  emptyProfileScreen: false,
  classes: '',
  forceNoTooltip: false,
  userHasEmail: false,
  handleNonEmailUser: false,
  spinnerSize: null,
  innerRef: null,
  tooltipFlip: true,
  tooltipPlacement: 'left',
  trackingId: undefined,
  isActive: false,
  noSpinnerOnLoad: false,
  noCopy: false,
  hideTooltipOnScroll: false,
  isSrp: false,
  wrapperClasses: '',
  ariaLabel: undefined,
};

export const mapStateToProps = state => ({
  isMobile: state.mobile,
  emptyIdmData: isEmpty(get(state, 'profile.data.idm')),
  emptyProfileScreen: isEmpty(get(state, 'profile.screen')),
});

export const InventoryHeart = connect(mapStateToProps)(connectedInsiderSaveVinWrapper(InventoryHeartUI, true));
export const InventoryHeartSave = connect(mapStateToProps)(connectedInsiderSaveVinWrapper(InventoryHeartUI, false));

InventoryHeart.propTypes = {
  vehicle: PropTypes.shape({}).isRequired,
  CustomView: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  wrapperClasses: PropTypes.string,
  ariaLabel: PropTypes.string,
};

InventoryHeart.defaultProps = {
  CustomView: null,
  wrapperClasses: '',
  ariaLabel: undefined,
};
