import { formatPriceString } from 'site-modules/shared/utils/price-utils';

/**
 * Return text for savings label
 *
 * @param {Number} savings
 * @returns {String}
 */
export function getSavingsText(savings = 0) {
  const savingsText = savings >= 0 ? 'Below Market' : 'Above Market';
  return `${formatPriceString(Math.abs(savings))} ${savingsText}`;
}
