import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';

let firstClientX;
let clientX;

export const preventTouch = e => {
  const minValue = 5; // threshold

  clientX = get(e, 'touches[0].clientX', firstClientX) - firstClientX;

  // Prevents vertical scrolling from occurring when you start swiping horizontally.
  if (Math.abs(clientX) > minValue) {
    e.preventDefault();
    e.returnValue = false;

    return false;
  }
  return true;
};

export const touchStart = e => {
  firstClientX = get(e, 'touches[0].clientX', 0);
};

export function PreventVerticalScrollOnSwipe({ children }) {
  const container = useRef();

  useEffect(() => {
    const { current } = container;
    current.addEventListener('touchstart', touchStart);
    current.addEventListener('touchmove', preventTouch, {
      passive: false,
    });

    return () => {
      current.removeEventListener('touchstart', touchStart);
      current.removeEventListener('touchmove', preventTouch, {
        passive: false,
      });
    };
  }, []);

  return <div ref={container}>{children}</div>;
}

PreventVerticalScrollOnSwipe.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};
