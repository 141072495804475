import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './heart-button.scss';

export function HeartButton({
  onClick,
  trackingId,
  trackingValue,
  trackingSubactionName,
  inProgress,
  isActive,
  className,
  blueTheme,
  ariaLabelExtra,
  isSrp,
}) {
  const rules = {
    'blue-theme': !isSrp && blueTheme,
    'icon-heart4': !isSrp && !isActive,
    'icon-heart3': !isSrp && isActive,
  };

  return (
    <button
      className={classNames('heart-button', className, rules, {
        'animated mt-0_5 mr-0_5': isSrp,
        active: isActive && isSrp,
      })}
      disabled={inProgress}
      onClick={onClick}
      data-tracking-id={trackingId}
      data-tracking-value={trackingValue}
      data-subaction-name={trackingSubactionName}
      title={isActive ? 'Click to remove' : 'Click to save'}
      aria-label={isActive ? `Click to remove favorite ${ariaLabelExtra}` : `Click to save favorite ${ariaLabelExtra}`}
      aria-pressed={isActive}
    >
      {isSrp && (
        <div className="d-flex h-100 justify-content-center">
          <svg width={18} height={15} viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden>
            <path
              d="M2.60586 1.53105C4.0571 0.556365 7.5 1.01789 9 3.69685C10.5 1.0179 13.9343 0.556366 15.383 1.53105C18.4479 3.59316 16.2928 7.32921 15.8662 7.86753C13.9346 10.3047 10.5 12.6651 9 13.5619C7.5 12.6651 3.40686 9.81748 2.12177 7.86753C1.7433 7.29325 -0.46449 3.59316 2.60586 1.53105Z"
              fill={isActive ? '#007EE5' : 'white'}
              stroke={isActive ? '#007EE5' : '#555555'}
              strokeWidth={2}
              strokeLinecap="round"
            />
          </svg>
        </div>
      )}
    </button>
  );
}

HeartButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  trackingId: PropTypes.string.isRequired,
  trackingValue: PropTypes.string,
  trackingSubactionName: PropTypes.string,
  isActive: PropTypes.bool,
  inProgress: PropTypes.bool,
  blueTheme: PropTypes.bool,
  className: PropTypes.string,
  ariaLabelExtra: PropTypes.string,
  isSrp: PropTypes.bool,
};

HeartButton.defaultProps = {
  isActive: false,
  inProgress: false,
  blueTheme: false,
  trackingValue: null,
  trackingSubactionName: null,
  className: null,
  ariaLabelExtra: '',
  isSrp: false,
};
