import { get, isNil } from 'lodash';

export const isRecurrentAvailable = vehicle => {
  const rangeScore = get(vehicle, 'thirdPartyInfo.recurrentInsights.rangeScore');
  const rangeScoreDescriptor = get(vehicle, 'thirdPartyInfo.recurrentInsights.rangeScoreDescriptor');
  const expectedRangeMin = get(vehicle, 'thirdPartyInfo.recurrentInsights.expectedRange.min');
  const expectedRangeMax = get(vehicle, 'thirdPartyInfo.recurrentInsights.expectedRange.max');

  return !isNil(rangeScore) && !!rangeScoreDescriptor && !isNil(expectedRangeMin) && !isNil(expectedRangeMax);
};

export const isBatteryRangeModuleEnabled = vehicle => {
  const isRecurrent = isRecurrentAvailable(vehicle);
  const epaRange = get(vehicle, 'vehicleInfo.partsInfo.electricityRange');
  const batteryCapacityKWH = get(vehicle, 'vehicleInfo.styleInfo.fuel.batteryCapacityKwh');
  const isSimplifiedBatteryRange = !!epaRange || !!batteryCapacityKWH;

  return isRecurrent || isSimplifiedBatteryRange;
};
