import { TrackingConstant } from 'client/tracking/constant';
import { EventToolbox } from 'client/utils/event-toolbox';
/**
 * Generates an object with tracking ID and value
 * @param {string} value Facet name
 * @returns {{"data-tracking-id": string, "data-tracking-value": string}}
 */
export function getFacetTrackingParams(value = '') {
  return {
    'data-tracking-id': TrackingConstant.ACTION_INVENTORY_SEARCH_FILTER,
    'data-tracking-value': value,
  };
}

/**
 * Generates an object with tracking ID and value
 * @param {string} value Facet name
 * @returns {{"data-tracking-id": string, "data-tracking-value": string}}
 */
export function getSelectedFacetTrackingParams(value = '') {
  return {
    'data-tracking-id': TrackingConstant.ACTION_INVENTORY_SEARCH_FILTER_REMOVE,
    'data-tracking-value': value,
  };
}

export function getFacetTrackingParamsWithDefaultValue() {
  return {
    'data-tracking-id': TrackingConstant.ACTION_INVENTORY_SEARCH_FILTER,
  };
}

export function getSelectedFacetTrackingParamsSemanticSearch({ value, searchId }) {
  return {
    'data-tracking-id': TrackingConstant.ACTION_INVENTORY_SEARCH_FILTER_REMOVE,
    'data-tracking-value': `srp_search_id=${searchId}&filters=${value}`,
  };
}

export function trackShowSilhouette(subactionName, vin, url) {
  EventToolbox.fireTrackAction({
    event_type: TrackingConstant.EVENT_TYPE_ACTION_COMPLETED,
    event_data: {
      action_category: TrackingConstant.SYSTEM_ACTION_CATEGORY,
      action_name: TrackingConstant.ACTION_SHOW_CONTENT,
      action_cause: TrackingConstant.PAGE_LOAD_CAUSE,
      subaction_name: subactionName,
      value: url ? `${vin}-${url.substring(url.lastIndexOf('/') + 1)}` : vin,
    },
  });
}
