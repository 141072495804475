import { VisitorModel } from 'client/data/models/visitor';
import { ClientConfig } from 'client/configuration';

const xVisitorId = 'x-visitor-id';

/**
 * Returns default API options from context
 * @param {Object} context Model segment context
 */
export const getDefaultApiOptionsFromContext = async context => {
  const visitorId = await context.resolveValue('id', VisitorModel);
  return { headers: { [xVisitorId]: visitorId } };
};

/**
 * Returns default API headers based on visitorId
 * @param {String} visitorId - visitor id
 * @param {String} excludeApiKey - exclude api key from headers
 */
export const getDefaultApiHeaders = (visitorId, excludeApiKey) => {
  const visitorIdHeader = visitorId ? { [xVisitorId]: visitorId } : {};
  const apiKeyHeader = excludeApiKey ? {} : { 'x-api-key': ClientConfig.get('carBuying').xApiKey };
  return { ...visitorIdHeader, ...apiKeyHeader };
};
