/* eslint-disable camelcase */
import { forIn, get, toString } from 'lodash';
import { EventToolbox } from 'client/utils/event-toolbox';
import { TrackingConstant } from 'client/tracking/constant';
import { getVehiclePrices } from 'client/actions/vehicle';

export function getInvId(vin) {
  return get(vin, 'vid', '');
}
export function getInvVin(vin) {
  return get(vin, 'vin', '');
}
export function getFranchiseId(vin) {
  return get(vin, 'dealerInfo.franchiseId', get(vin, 'dealerInfo.id'));
}
export function getRooftopId(vin) {
  return get(vin, 'dealerInfo.rooftopId');
}

/**
 * Returns the first inventory data.
 * @param {Object[]} inventory The array of inventories
 * @returns {Object}
 */
function getFirstVehicle(inventory = []) {
  const firstVehicle = inventory[0] || {};
  const { price, salePrice } = getVehiclePrices(firstVehicle.prices);
  return {
    make: get(firstVehicle, 'vehicleInfo.styleInfo.make', ''),
    model: get(firstVehicle, 'vehicleInfo.styleInfo.model', ''),
    year: get(firstVehicle, 'vehicleInfo.styleInfo.year', ''),
    type: get(firstVehicle, 'type', ''),
    interiorColor: get(firstVehicle, 'vehicleInfo.vehicleColors.interior.genericName', ''),
    exteriorColor: get(firstVehicle, 'vehicleInfo.vehicleColors.exterior.genericName', ''),
    price,
    salePrice,
    trim: get(firstVehicle, 'vehicleInfo.styleInfo.trim', ''),
    bodyType: get(firstVehicle, 'vehicleInfo.styleInfo.bodyType', ''),
  };
}

/**
 * Returns list tracking data.
 * @param {Object[]} inventory
 * @returns {Object}
 */
export function getListTrackingData(inventory) {
  const loc_id_list = [];
  const f_id_list = [];
  const inv_id_list = [];
  const vin_list = [];

  forIn(inventory, vin => {
    if (!vin.isSoldVin) {
      f_id_list.push(getFranchiseId(vin));
      loc_id_list.push(getRooftopId(vin));
      inv_id_list.push(getInvId(vin));
      vin_list.push(getInvVin(vin));
    }
  });

  return {
    loc_id_list: toString(loc_id_list),
    f_id_list: toString(f_id_list),
    inv_id_list: toString(inv_id_list),
    vin_list: toString(vin_list),
  };
}

export function fireListTracking(inventory, creativeId, extendObj = {}) {
  const eventData = {
    action_name: 'list',
    action_cause: 'page_load',
    action_category: 'system',
    creative_id: creativeId,
    ...getListTrackingData(inventory),
    ...extendObj,
  };

  EventToolbox.fireTrackAction({
    event_type: 'action_completed',
    event_data: eventData,
    first_vehicle: getFirstVehicle(inventory),
  });
}

export const ListEngagementHandler = {
  /**
   * Setups "pageLoad"" event listener
   *
   * @param  {Object} getState Returns App redux store state
   * @return {void}
   */
  init() {
    EventToolbox.on(TrackingConstant.ACTION_LIST_VIEWED, event => {
      fireListTracking(event.detail.inventory, event.detail.creativeId, event.detail.extendObj);
    });
  },
};
